'use client';

import {
  Stack,
  Container,
  Button,
  Box,
  Text,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import Link from 'next/link';
import type { ReactNode } from 'react';

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as="span" fontWeight={700}>
    {children}
  </Text>
);
const ctaText = 'Learn more';
const stats = [
  {
    title: 'Serverless',
    cta: {
      text: null,
      href: new URL('/serverless', process.env.NEXT_PUBLIC_HOST_DOCS).href,
    },
    content: (
      <>
        We deploy and scale your apps globally; you focus on{' '}
        <StatsText> building amazing models </StatsText>
      </>
    ),
  },
  {
    title: 'Monetize',
    cta: {
      text: ctaText,
      href: new URL('/monetization', process.env.NEXT_PUBLIC_HOST_DOCS).href,
    },
    content: (
      <>
        <StatsText>Earn Cash:</StatsText> per requests, per month, per license,
        per your requirements
      </>
    ),
  },
  {
    title: 'Flexible Integration',
    cta: {
      text: ctaText,
      href: new URL('/uploading-models', process.env.NEXT_PUBLIC_HOST_DOCS)
        .href,
    },
    content: (
      <>
        <StatsText>Develop</StatsText> with your favorite tools.{' '}
        <StatsText>Launch</StatsText> with ours
      </>
    ),
  },
  {
    title: 'Quickstart Templates',
    cta: {
      text: ctaText,
      href: 'https://github.com/DeployModel/deploymodel-templates',
    },
    content: (
      <>
        <StatsText>LLM, Vision, Voice</StatsText> and much more. Regardless of
        your application, you&apos;ll get started in minutes
      </>
    ),
  },
  {
    title: 'Free or Pay As You Go',
    cta: {
      text: ctaText,
      href: new URL('/pricing', process.env.NEXT_PUBLIC_HOST_DOCS).href,
    },
    content: (
      <>
        <StatsText>Only pay for what you use:</StatsText> No hidden fees, no
        subscriptions. It&apos;s free when using{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={
            new URL('/monetization#license', process.env.NEXT_PUBLIC_HOST_DOCS)
              .href
          }
        >
          Licensing
        </a>
      </>
    ),
  },
  {
    title: 'A.I. Optimized',
    cta: {
      text: ctaText,
      href: new URL('/ai-assistant', process.env.NEXT_PUBLIC_HOST_DOCS).href,
    },
    content: (
      <>
        Let our A.I. assistant run tests on your models to{' '}
        <StatsText>optimize your cluster</StatsText>
      </>
    ),
  },
];

export default function Features() {
  return (
    <Box position="relative">
      {/* <Flex
        flex={1}
        zIndex={0}
        display={{ base: 'none', lg: 'flex' }}
        backgroundImage="url('/templates/stats-grid-with-image.png')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position="absolute"
        width="50%"
        insetY={0}
        right={0}
      >
        <Flex
          bgGradient="linear(to-r, gray.800 10%, transparent)"
          w="full"
          h="full"
        />
      </Flex> */}
      <Container maxW="7xl" zIndex={10} position="relative">
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Stack
            flex={1}
            color="gray.400"
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 4, xl: 4 }}
          >
            <Box mb={{ base: 4, md: 4 }}>
              {/* <Text
                fontFamily="heading"
                fontWeight={700}
                textTransform="uppercase"
                mb={3}
                fontSize="xl"
                color="gray.500"
              >
                Technology
              </Text> */}
              <Container maxW="5xl" mb="12">
                <Heading
                  textAlign="center"
                  color="white"
                  mb={5}
                  fontSize={{ base: '3xl', md: '5xl' }}
                >
                  Infrastructure tasks slowing you down?
                </Heading>
                <Text fontSize="xl" color="gray.400" textAlign="center">
                  We solve the backend & deployment, so your developers &
                  researchers can focus on innovation
                </Text>
              </Container>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily="heading"
                    fontSize="3xl"
                    color="white"
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text
                    fontSize="xl"
                    color="gray.400"
                    style={{ textAlign: 'justify' }}
                  >
                    {stat.content}
                  </Text>
                  <Button variant="link" fontSize="xl" color="hypertext" mt={2}>
                    <Link href={stat.cta.href}>{stat.cta.text}</Link>
                  </Button>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
