import {
  Button,
  Center,
  Container,
  Heading,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';

const LandingHero = () => {
  return (
    <Container maxW="container.lg">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Container maxW="container.md" textAlign="center">
            <Heading size="3xl" mb={4} as="h1">
              Build. Deploy. Monetize.
            </Heading>
            <Text fontSize="xl" color="gray.400" as="h2">
              Launch to production in minutes and unlock new revenue
              opportunities effortlessly.
            </Text>
            <HStack justify="center">
              <Link href="/model">
                <Button colorScheme="brand" mt={8} me={2}>
                  Get Started
                </Button>
              </Link>
              <Button
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/deploymodel/call"
                colorScheme="brand"
                variant="link"
                mt={8}
                ms={2}
              >
                Book a demo
              </Button>
            </HStack>

            {/* <Text my={2} fontSize="sm" color="gray.500">
              102+ builders have signed up in the last 30 days
            </Text> */}
          </Container>
        </VStack>
      </Center>
    </Container>
  );
};

export default LandingHero;
