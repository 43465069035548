'use client';

import { Flex, Button, HStack } from '@chakra-ui/react';
import Link from 'next/link';
import type { UserRead } from 'typescript-axios';

import Features from '~/lib/components/samples/features';
import LandingHero from '~/lib/components/samples/landing-hero';
import { UserProvider } from '~/lib/contexts/UserContext';

// const USER_API = new UserApi(getAxiosParams(), undefined, axios);

// const getData = async () => {
//   return USER_API.readUserMeApiV1UserMeGet({
//     headers: currentHeaders,
//   });
// };

interface HomeProps {
  user: UserRead | null;
}

const Home = ({ user }: HomeProps) => {
  return (
    <UserProvider value={{ user }}>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        minHeight="70vh"
        gap={4}
        mb={8}
        w="full"
      >
        <LandingHero />
        <Features />
        {/* <AlgorithmsGrid versions={versions} /> */}
        {/* <CTASection /> */}
        {/* <Runs /> */}

        <HStack mt={8}>
          <Link href="/model">
            <Button colorScheme="brand">Get Started</Button>
          </Link>
          <Link href="/docs">
            <Button>Documentation</Button>
          </Link>
        </HStack>
      </Flex>
    </UserProvider>
  );
};

export default Home;
